import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-02";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import ContactArea from "@containers/contact/layout-02";
import { Container } from "react-bootstrap";

const IdentityAccessManagement = ({ pageContext, location, data }) => {
    const content = normalizedData(data?.page?.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo titleTemplate="TEQT" title="IdentityAccessManagement"
                description="We have successfully completed $40,000,000 worth of digital marketing projects for more than 5,000 clients in more than 120 countries." />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader
                    pageContext={pageContext}
                    location={location}
                    title="IT outsourcing company"
                />
                <div className="service-container">
                    <Container className="mb-5">
                        <p className="mt-5">The classic perimeter of IT organization has become non-existent with the adoption of cloud (IaaS, PaaS, SaaS), distributed offices, mobile, etc.  This has resulted in incompatible security models and a lack of governance controls leading to increased risk of identity theft, unauthorized access, and failure to meet compliance requirements.</p>
                        <p className="mt-3">Identity and Access Management (IDAM) has become the new perimeter for the modern enterprise that helps in defining and managing the roles and access privileges of individual users and the circumstances in which users are granted (or denied) those privileges. </p>
                        <p className="mt-2">TEQT helps deliver comprehensive identity and access management solutions to customers through our ACE (Access Control & Entity Management) platform-based services.</p>
                        <p className="mt-2">ACE is powered by industry-leading products, to deliver an advanced centralized access management service to customers. TEQT ACE platform enables customers in pursuing their digital transformation journey without compromising on security and maintaining compliance with regulatory requirements.</p>
                        <h6>The features of ACE platform-based services include:</h6>
                        <h6 className="mt-3">Centralized Authentication</h6>
                        <ul className="circle-list">
                            <li className="mt-2">Single Sign-On</li>
                            <li className="mt-2">Session Management</li>
                            <li className="mt-2">Password Management</li>
                            <li className="mt-2">Strong Authentication</li>
                        </ul>
                        <h6 className="mt-3">User Management</h6>
                        <ul className="circle-list">
                            <li className="mt-2">Delegated Administration</li>
                            <li className="mt-2">User, Role, and Session Management</li>
                            <li className="mt-2">Privileged Account Management</li>
                            <li className="mt-2">Self-Service and automated provisioning, de-provisioning & Password Management</li>
                        </ul>

                        <h6 className="mt-3">Central User Store Repository</h6>
                        <ul className="circle-list">
                            <li className="mt-2">Directory (Meta directory & Virtual directory)</li>
                            <li className="mt-2">Data Synchronization</li>
                        </ul>

                        <h6 className="mt-3">Central User Store Repository</h6>
                        <ul className="circle-list">
                            <li className="mt-2">Directory (Meta directory & Virtual directory)</li>
                            <li className="mt-2">Data Synchronization</li>
                        </ul>

                        <h6 className="mt-3">Authorization</h6>
                        <ul className="circle-list">
                            <li className="mt-2">Role & Attribute-Based</li>
                            <li className="mt-2">Rule-Based</li>
                            <li className="mt-2">Remote Authorization</li>
                        </ul>
                    </Container>
                </div>
                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query IdentityAccessManagementPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderTwo
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "it-solutions" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

IdentityAccessManagement.propTypes = {
    pageContext: PropTypes.shape({}),
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default IdentityAccessManagement;
